import { Grid, RadioGroup, Container, ThemeProvider } from "@mui/material";
import { MultipleChoice, OpenText } from "../../Components";
import { Link } from "react-router-dom";
import {
  setResponse,
  setOpenTextChanges,
  setRadioChanges,
  setMultipleChoiceChanges,
  clearRedux,
  updateCategoryIndex,
  updateQuestionIndex,
} from "../../Store/Reducers/ResponseSlice";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOneForm, postOneForm, sendToZapier } from "../../Apis/Response";
import { InitialContext } from "../../Utils/Context/InitailContext";
import classes from "./Response.module.css";
import RadioInput from "../../Components/Radio/RadioButton";
import { useDispatch, useSelector } from "react-redux";
import Progress from "../../Components/Progress/Progress";
import { setCategoryConstants } from "../../Store/Reducers/ConstantSlice";
import HorizontalRadio from "../../Components/HorizontalRadio/HorizontalRadio";
import ResponsePlaceholders from "../../Components/PlaceHolders/ResponsePlaceholders";
import { theme } from "../../Utils/ThemeProvider/ThemeProvider";
import { AnalyticEvents } from "../../Utils/Helpers/helpers";
import { CopylUrl } from "../../Utils/BaseUrl/BaseUrl";

const Response = () => {
  const progressRef = useRef();
  const dispatch = useDispatch();
  let { key } = useParams();
  const initial = useContext(InitialContext);

  const [currentPageStart, setCurrentPageStart] = useState(new Date());
  const [currentSessionStart, setCurrentSessionStart] = useState(new Date());

  const { data, isSuccess, isLoading, questionIndex, categoryIndex } =
    useSelector((state) => state?.initialResponse);
  const { userData } = useSelector((state) => state?.userData);
  const fetchData = async () => {
    let response = await getOneForm(key);
    if (response !== 404) {
      dispatch(setResponse(response));
      dispatch(setCategoryConstants(response));
    } else {
      initial.setSnacky({
        color: "error",
        message:
          "An Error Occurred while fetching the From! please contact Admin",
        open: true,
      });
      console.log(response);
    }
  };
  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, []);

  const handleTextValue = (e, chars) => {
    let obj = {
      value: e.target.value,
      questionIndex,
      categoryIndex,
      chars,
      data,
    };
    dispatch(setOpenTextChanges(obj));
  };

  const handleRadioChange = (value) => {
    let obj = {
      value: value,
      questionIndex,
      categoryIndex,
      data,
    };
    dispatch(setRadioChanges(obj));
  };

  const checkBoxChange = (value) => {
    let obj = {
      value: value,
      questionIndex,
      categoryIndex,
      data,
    };
    dispatch(setMultipleChoiceChanges(obj));
  };

  const [funcString, setFuncString] = useState("");

  const sendPageTimeAnalytics = () => {
    AnalyticEvents(
      JSON.stringify({
        categoryIndex: categoryIndex,
        questionIndex: questionIndex,
        timeSpent: Number(
          (new Date().getTime() - currentPageStart.getTime()) / 1000
        ),
      }),
      "Assessment",
      "Time to completion (each page)"
    );
    setCurrentPageStart(new Date());
  };

  const sendSessionTimeAnalytics = (isReview = false) => {
    const finishedAt = new Date();
    AnalyticEvents(
      JSON.stringify({
        startedAt: currentSessionStart,
        finishedAt: finishedAt,
        timeSpent: Number(
          (finishedAt.getTime() - currentSessionStart.getTime()) / 1000
        ),
      }),
      "Assessment",
      isReview ? "Time to completion (full assessment) - submitted for review" : "Time to completion (full assessment)"
    );
  };

  const analyticalNextFunc = () => {
    setFuncString("next");
    sendPageTimeAnalytics();
    if (data.categories[categoryIndex].questions.length - 1 > questionIndex) {
      dispatch(updateQuestionIndex(questionIndex + 1));
      const filteredArray = data?.categories[categoryIndex].questions[
        questionIndex
      ].choices.filter((element) => {
        return data?.categories[categoryIndex].questions[
          questionIndex
        ].answers.includes(element.id);
      });
      let tempObject = {
        category: data?.categories[categoryIndex].kind,
        question: data?.categories[categoryIndex].questions[questionIndex].text,
        description:
          data?.categories[categoryIndex].questions[questionIndex].description,
        answer_text:
          data?.categories[categoryIndex].questions[questionIndex].answer_text,
        answers: filteredArray,
      };
      AnalyticEvents(
        JSON.stringify(tempObject),
        "Matter's Form Event",
        "The Form Fields"
      );
    } else {
      dispatch(updateCategoryIndex(categoryIndex + 1));
      dispatch(updateQuestionIndex(0));
    }
  };
  const handleNext = () => {
    if (data?.categories[categoryIndex].questions[questionIndex].required) {
      if (data?.categories[categoryIndex].questions[questionIndex].selected) {
        analyticalNextFunc();
      }
    } else {
      analyticalNextFunc();
    }
  };
  const handleBack = () => {
    setFuncString("back");
    sendPageTimeAnalytics();
    if (questionIndex === 0 && categoryIndex !== 0) {
      dispatch(updateCategoryIndex(categoryIndex - 1));
      dispatch(
        updateQuestionIndex(
          data.categories[categoryIndex - 1].questions.length - 1
        )
      );
    } else if (questionIndex !== 0) {
      dispatch(updateQuestionIndex(questionIndex - 1));
    }
  };

  useEffect(() => {
    if (progressRef.current) {
      if (funcString === "back") {
        progressRef.current.handleStyleUpdate("back");
      } else if (funcString === "next") {
        progressRef.current.handleStyleUpdate("next");
      }
    }
  }, [categoryIndex, questionIndex]);

  const checkError = () => {
    let ans = false;
    data.categories.forEach((cat) => {
      cat.questions.forEach((question) => {
        if (question.required && question.error) {
          ans = true;
          return ans;
        }
      });
    });
    return ans;
  };
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let check = checkError();
    if (check) {
      setErrors(true);
      initial.setSnacky({
        color: "error",
        message: "Please Review the form! some fields are not filled ",
        open: true,
      });
      return;
    } else {
      setErrors(false);
    }
    const finalData = {
      form_id: data.id,
      categories_answers: data.categories,
      startup_id: userData.startup_id,
      user_id: userData.id,
    };
    const result = await postOneForm(finalData);
    localStorage.setItem('form_key', result.key);
    if (result.error) {
      initial.setSnacky({
        color: "error",
        message: result.message,
        open: true,
      });
      setLoading(false);
    } else {
      const zapierData = {
        ...finalData,
        categories_answers: JSON.stringify({
          data: finalData.categories_answers
        }),
        link_to_result: `${CopylUrl}result/${result.key}`
      };
      await sendToZapier(zapierData);

      initial.setSnacky({
        color: "success",
        message: "Response Saved",
        open: true,
      });
      sendSessionTimeAnalytics();
      dispatch(clearRedux("clear"));
      history("/congrats");
    }
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main_container}>
        <Grid container className={`${classes.progress_container}`}>
          <Grid item style={{ overflow: "hidden" }}>
            {isLoading ? (
              <ResponsePlaceholders />
            ) : (
              isSuccess && (
                <Progress
                  ref={progressRef}
                  stages={data.categories}
                  questionIndex={questionIndex}
                  categoryIndex={categoryIndex}
                />
                // <div className={classes.test}></div>
              )
            )}
          </Grid>
        </Grid>
        {isLoading ? (
          <ResponsePlaceholders
            width={"30rem"}
            variant={"rounded"}
            amount={10}
            animarion={"wave"}
          />
        ) : (
          <Grid container className={classes.main_response_section}>
            {isSuccess &&
              data &&
              data.categories[categoryIndex] &&
              data.categories[categoryIndex].questions[questionIndex] && (
                <Grid item className={classes.main_wrapper}>
                  {/*<Box*/}
                  {/*    className={classes.response_txt}*/}
                  {/*>*/}
                  <Grid item className={classes.section_container}>
                    <Container
                      disableGutters
                      style={{ display: "flex", paddingBottom: "2.25vh" }}
                    >
                      <p className={classes.number_text}>
                        {questionIndex + 1}/
                        {data.categories[categoryIndex].questions.length}
                      </p>
                      <p
                        className={classes.question_text}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <p>
                          {
                            data.categories[categoryIndex].questions[
                              questionIndex
                            ].text
                          }
                        </p>
                        <p className={classes.question_description}>
                          {
                            data.categories[categoryIndex].questions[
                              questionIndex
                            ].description
                          }
                        </p>
                      </p>
                    </Container>
                    {/*<Box className={classes.content_wrapper}>*/}
                    {data.categories[categoryIndex].questions[questionIndex]
                      .question_type === "SC" ? (
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        // value={value}
                        // onChange={handleChange}
                      >
                        {data.categories[categoryIndex].questions[questionIndex]
                          .is_horizontal ? (
                          <div className={classes.horizontal_padding}>
                            <HorizontalRadio
                              options={
                                data.categories[categoryIndex].questions[
                                  questionIndex
                                ].choices
                              }
                              answer={
                                data.categories[categoryIndex].questions[
                                  questionIndex
                                ].answers[0]
                              }
                              onChange={handleRadioChange}
                              label={
                                data.categories[categoryIndex].questions[
                                  questionIndex
                                ].text
                              }
                            />
                          </div>
                        ) : (
                          <RadioInput
                            options={
                              data.categories[categoryIndex].questions[
                                questionIndex
                              ].choices
                            }
                            answer={
                              data.categories[categoryIndex].questions[
                                questionIndex
                              ].answers[0]
                            }
                            onChange={handleRadioChange}
                            label={
                              data.categories[categoryIndex].questions[
                                questionIndex
                              ].text
                            }
                          />
                        )}
                      </RadioGroup>
                    ) : data.categories[categoryIndex].questions[questionIndex]
                        .question_type === "CB" ? (
                      <Container
                        sx={{ width: "unset" }}
                        className={classes.mc_parent}
                      >
                        <div
                          className={`${classes.scrollable_div} ${classes.multiple_choice_container}`}
                        >
                          {data.categories[categoryIndex].questions[
                            questionIndex
                          ].choices.map((val, valIndex) => (
                            <MultipleChoice
                              labelText={val.option}
                              key={valIndex}
                              val={val}
                              answer={
                                data.categories[categoryIndex].questions[
                                  questionIndex
                                ].answers
                              }
                              checkBoxChange={checkBoxChange}
                            />
                          ))}
                        </div>
                      </Container>
                    ) : (
                      <Grid item lg={10} className={classes.ot_grid}>
                        <OpenText
                          description={
                            data.categories[categoryIndex].questions[
                              questionIndex
                            ].description
                          }
                          handleTextValue={handleTextValue}
                          value={
                            data.categories[categoryIndex].questions[
                              questionIndex
                            ]
                          }
                        />
                      </Grid>
                    )}
                    {/*</Box>*/}
                  </Grid>
                  {/*</Box>*/}
                </Grid>
              )}
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              xl={12}
              className={classes.last_section}
            >
              <button
                className="back_btn"
                disabled={
                  isSuccess && categoryIndex === 0 && questionIndex === 0
                }
                onClick={handleBack}
              >
                {isSuccess && categoryIndex === 0 && questionIndex === 0 ? (
                  <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                ) : (
                  "Back"
                )}
              </button>
              {isSuccess &&
                categoryIndex === data.categories.length - 1 &&
                questionIndex ===
                  data.categories[categoryIndex].questions.length - 1 && (
                  <Link
                    to={{
                      pathname: "/review",
                      state: { startedAt: currentSessionStart },
                    }}
                  >
                    <button className={classes.review_btn} onClick={sendSessionTimeAnalytics(true)}>Review</button>
                  </Link>
                )}
              {isSuccess &&
              categoryIndex === data.categories.length - 1 &&
              questionIndex ===
                data.categories[categoryIndex].questions.length - 1 ? (
                <button
                  className="next_btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Submit
                </button>
              ) : (
                <button
                  className={
                    data?.categories[categoryIndex].questions[questionIndex]
                      .selected
                      ? "next_btn"
                      : classes.next_checked
                  }
                  onClick={handleNext}
                >
                  Next
                </button>
              )}
              {/*<Link to="/review">abc</Link>*/}
              {/*<button onClick={()=>dispatch(clearRedux("clear"))}>clear</button>*/}
            </Grid>
          </Grid>
        )}
      </div>
    </ThemeProvider>
  );
};
export default Response;
