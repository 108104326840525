import React, { useContext, useState, useEffect } from "react";
import styles from "./NewDesign.module.css";
import Rating from "@mui/material/Rating";
import CircleIcon from "@mui/icons-material/Circle";
import { useParams, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import mobileLogo from "../../assets/mobilelogo.png";
import circlesImage from "../../assets/circles.svg";
import arrowImage from "../../assets/arrow.svg";
import RadarChart from "../../Components/RadarChart/RadarChart";
import {
  StartupScore,
  StartupGoals,
  getFilters,
  searchFilters,
  generalPatchRequest,
} from "../../Apis/StartupScore";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import CustomizedAccordions from "./AccordionNew";
import { AnalyticEvents } from "../../Utils/Helpers/helpers";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const NewDesign = () => {
  const [expandedItems, setExpandedItems] = useState({});
  const [value, setValue] = React.useState(2);
  const [currentCategory, setCurrentCategory] = useState("");
  const [bullets, setBullets] = useState({});
  const [results, setResults] = useState(true);
  const [goal, setGoal] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [recommendations, setRecommendations] = useState(false);
  const [userFav, setUserFav] = useState([]);
  const [startupDetails, setStartupDetails] = useState({});
  const [resourcesByGoal, setResourcesByGoal] = useState({});
  const { key: scorecard, type } = useParams();
  const [open, setOpen] = React.useState(true);
  const [defaultOpen, setDefaultOpen] = React.useState(true);
  const [showingFilter, setShowingFilter] = React.useState(
    "Your upcoming content"
  );
  const [category, setCategory] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDefaultClose = () => setDefaultOpen(false);
  const [currentPageStart, setCurrentPageStart] = React.useState(new Date());
  const [currentSessionStart, setCurrentSessionStart] = React.useState(
    new Date()
  );
  const [selectedTextValues, setSelectedTextValues] = useState(JSON.parse(localStorage.getItem(`goals-${scorecard}`)) || []);
  const history = useNavigate();

  useEffect(() => {
    if (type === "results") {
      setResults(true);
      setGoal(false);
      setRecommendations(false);
    } else if (type === "goal") {
      setResults(false);
      setGoal(true);
      setRecommendations(false);
    } else if (type === "recommendations") {
      setResults(false);
      setGoal(false);
      setRecommendations(true);
      submitGoals();
    }
  }, []);

  const sendPageTimeAnalytics = (category) => {
    AnalyticEvents(
      JSON.stringify({
        category: category,
        timeSpent: Number(
          (new Date().getTime() - currentPageStart.getTime()) / 1000
        ),
      }),
      "Roadmap",
      "Time to completion (each page)"
    );
    setCurrentPageStart(new Date());
  };

  const sendSessionTimeAnalytics = () => {
    AnalyticEvents(
      JSON.stringify({
        url: window.location.href,
        timeSpent: Number(
          (new Date().getTime() - currentSessionStart.getTime()) / 1000
        ),
      }),
      "Roadmap",
      "Time to completion (roadmap)"
    );
  };

  useEffect(() => {
    return () => {
      sendPageTimeAnalytics("recommendations");
      sendSessionTimeAnalytics();
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 580,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "16px",
    padding: "32px",
  };

  const goals = {
    business: [
      {
        name: "financial_business",
        text: "Develop financial and business model",
      },
      {
        name: "strategy",
        text: "Develop brand and go-to-market strategy",
      },
      {
        name: "raise_money",
        text: "Raise money",
      },
      {
        name: "sales_partners",
        text: "Close sales and find partners",
      },
    ],
    product: [
      {
        name: "proposition_market_fit",
        text: "Define value proposition and product-market fit",
      },
      {
        name: "design_develop",
        text: "Design and develop product(s)",
      },
      {
        name: "approval",
        text: "Obtain regulatory or legal approval",
      },
    ],
    team: [
      {
        name: "leader",
        text: "Become a better leader",
      },
      {
        name: "team",
        text: "Hire a solid team",
      },
      {
        name: "advisory",
        text: "Build an advisory board",
      },
    ],
  };
  const userFavDescriptionLimit = 100;

  const handleCheckboxChange = (event) => {
    const checkboxName = event.target.name;
    const textValue = event.target.nextSibling.textContent; // Get the label text

    if (event.target.checked) {
      // If checkbox is checked, add textValue to the state
      setSelectedTextValues((prevValues) => [...prevValues, textValue]);
    } else {
      // If checkbox is unchecked, remove textValue from the state
      setSelectedTextValues((prevValues) =>
        prevValues.filter((value) => value !== textValue)
      );
    }
  };

  const submitGoals = async () => {
    let request = {};
    request["form_response_key"] = scorecard;
    request["goals"] = selectedTextValues;
    AnalyticEvents(
      JSON.stringify({
        goals: selectedTextValues,
        count: selectedTextValues.length,
      }),
      "Roadmap",
      "Number of goals"
    );
    const data = await StartupGoals(request);
    let tempArray = [], tempResources = {};
    Object.keys(data.data).forEach((x) => {
      tempResources[x] = data.data[x].filter((a) => a.default_content === true);
      const xx = data.data[x];
      if (Array.isArray(xx) && xx.length !== 0) {
        xx.forEach((newEle) => {
          if (newEle.user_favorite) {
            tempArray.push(newEle);
          }
        });
      }
    })
    tempArray = tempArray.filter((a) => a.default_content === true);
    setUserFav(tempArray.slice(0, 4));
    setResourcesByGoal(tempResources);
  };

  const handlePatchRequestsFromChildComponent = async (
    dataFromChildComponent,
    requestType
  ) => {
    const data = await generalPatchRequest(
      requestType,
      dataFromChildComponent.id,
      scorecard
    );
    if (data) {
      setResourcesByGoal(data.data);
    }
  };

  const submitFilters = async (filter, category) => {
    let params = "";
    let cat = "";
    if (category !== "") {
      cat = category;
    } else {
      cat = "";
    }

    if (showingFilter === "Bookmarked") {
      params = "bookmarked=true";
    } else if (showingFilter === "Completed") {
      params = "completed=true";
    } else if (showingFilter === "Incomplete") {
      params = "incomplete=true";
    } else {
      params = "";
    }
    const { data: responseArr } = await getFilters(params, cat, scorecard);
    if (Object.keys(responseArr).length === 0) {
      setShowContent(true);
    } else {
      setShowContent(false);
      // setResourcesByGoal(data.data.resources_by_goal);
    }
    setResourcesByGoal(responseArr);
  };
  const toggleCollapse = (goal) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [goal]: !prevState[goal],
    }));
  };
  const searchFilter = async (e) => {
    e.preventDefault();
    AnalyticEvents(
      JSON.stringify({
        searchText: e.target.value,
      }),
      "Roadmap",
      "Search text"
    );
    const { data: responseArr } = await searchFilters(
      e.target.value,
      scorecard
    );
    setResourcesByGoal(responseArr);
  };

  useEffect(() => {
    (async () => {
      const updatedPercentages = {};
      const data = await StartupScore(scorecard);

      setStartupDetails(data[0]);
      for (const key in data[0].scaled_skewed_percentage) {
        const calculatedPercentage =
          (data[0].scaled_skewed_percentage[key] * 100) / 20;
        const roundedPercentage = Math.round(calculatedPercentage);
        let color;

        switch (roundedPercentage) {
          case 5:
            color = "rgba(0, 173, 208, 1)";
            break;
          case 4:
            color = "rgba(51, 189, 217, 1)";
            break;
          case 3:
            color = "rgba(102, 206, 227, 1)";
            break;
          case 2:
          case 1:
            color = "rgba(153, 222, 236, 1)";
            break;
          default:
            color = "rgba(153, 222, 236, 1)"; // Handle other cases here
        }

        updatedPercentages[key] = {
          percentage: roundedPercentage,
          color: color,
        };
      }

      for (const i in updatedPercentages) {
        if (updatedPercentages[i].percentage === 0) {
          updatedPercentages[i].percentage = 1;
        }
      }
      setBullets(updatedPercentages);
    })();
  }, []);
  useEffect(() => {
    submitFilters(showingFilter, category);
  }, [showingFilter, category]);

  return (
    <div>
      {results && (
        <Modal
          open={defaultOpen}
          onClose={handleDefaultClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ textAlign: "center" }}>
            {/* <button type="button" className={`btn float-end`} onClick={() => handleDefaultClose()} style={{position:'relative',top:'-20px'}}>x</button> */}
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div>
                <img src="/assets/hexagonal.png" width="80px" alt="" />
              </div>
            </Typography>
            <Typography
              id="modal-modal-description"
              className={`${styles.modal_title}`}
              sx={{ mt: 2 }}
            >
              Ready for your results?
            </Typography>
            <p
              className={`${styles.modal_description}`}
              style={{ textAlign: "center" }}
            >
              We’ve processed and reviewed your results and plotted them against
              our algorithm. On the left you can see a visualization of your
              strengths and opportunities for growth, and on the right we break
              down each section into more detail based on the questions with the
              most room for growth. Once you have reviewed the results, click on{" "}
              <b>Set your goals</b> to continue to your MATTER roadmap.
            </p>
            <button
              type="button"
              className={`${styles.set_your_goal_btn} btn`}
              style={{ float: "none", margin: "0 auto" }}
              onClick={() => handleDefaultClose()}
            >
              <img src={circlesImage} alt="" />
              See your results
              <img src={arrowImage} alt="" />
            </button>
          </Box>
        </Modal>
      )}
      <>
        <section className={`${styles.header_section} `}>
          <div className="row m-0">
            <nav
              className={`${styles.navbar} navbar navbar-expand-lg navbar-light px-3`}
            >
              <div className="container-fluid">
                <a
                  className={`navbar-brand ${styles.web_logo}`}
                  href="https://matter.health/"
                >
                  <img className={`${styles.cstm_logo}`} src={Logo} alt="" />
                </a>
                <a
                  className={`navbar-brand ${styles.mobile_logo}`}
                  href="https://matter.health/"
                >
                  <img
                    className={`${styles.cstm_logo}`}
                    src={mobileLogo}
                    alt=""
                  />
                </a>
                <a
                  className={`${styles.portal}`}
                  href="https://matter-health.my.site.com/s/"
                >
                  Portal
                </a>
                <div className="d-flex">
                  <div className="dropdown">
                    <button
                      className={`${styles.profile_dropdown} navbar_profile_dropdown btn dropdown-toggle`}
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    ></button>
                    <ul
                      className={`${styles.profile_dropdown_menu} dropdown-menu`}
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a className="dropdown-item" href="">
                          {/* <img src={avatarImage} alt="" /> */}
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </section>
      </>

      {!recommendations && (
        <section>
          <div className={`${styles.goal_step_section} `}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-9 ps-0">
                  <div className={`${styles.goal_steps} `}>
                    <ul className="ps-0 mb-0 mt-3">
                      <li
                        className={`${
                          results === true ||
                          goal === true ||
                          recommendations === true
                            ? styles.active
                            : ""
                        } ps-0`}
                      >
                        1. View your results
                      </li>
                      <li
                        className={`${goal === true ? styles.active : ""} ps-0`}
                      >
                        2. Set your goals
                      </li>
                      <li
                        className={`${
                          recommendations === true ? styles.active : ""
                        } ps-0`}
                      >
                        3. See recommendations
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3 pe-0">
                  {results && (
                    <button
                      type="button"
                      className={`${styles.set_your_goal_btn} btn`}
                      onClick={() => {
                        sendPageTimeAnalytics("results");
                        setResults(false);
                        setGoal(true);
                        history(`/scorecard/${scorecard}/goal`);
                      }}
                    >
                      <img src={circlesImage} alt="" />
                      Set your goals
                      <img src={arrowImage} alt="" />
                    </button>
                  )}
                  {goal && (
                    <button
                      type="button"
                      className={`${styles.set_your_goal_btn} btn`}
                      onClick={() => {
                        if (selectedTextValues.length > 0) {
                          sendPageTimeAnalytics("goals");
                          localStorage.setItem(`goals-${scorecard}`, JSON.stringify(selectedTextValues));
                          history(`/scorecard/${scorecard}/recommendations`);
                          setRecommendations(true);
                          setGoal(false);
                          submitGoals();
                        }
                      }}
                    >
                      <img src={circlesImage} alt="" />
                      See your recommendations
                      <img src={arrowImage} alt="" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {recommendations && (
        <section className={`${styles.recommended_top_section}`}>
          <div className="row">
            {userFav &&
              userFav.map((x, index) => (
                <React.Fragment key={index}>
                  <div className="col-md-3">
                    <div className={`${styles.cards} card`}>
                      {/* <img src="/assets/placeholder-img.svg" className="card-img-top" alt="..." /> */}
                      <img
                        src={
                          x.thumbnail
                            ? x.thumbnail
                            : "/assets/placeholder-img.svg"
                        }
                        className="rounded-3 card-img-top"
                        height="177.64px"
                        alt="..."
                        style={{ objectFit: "cover" }}
                      />
                      <div
                        className="card-body"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "177.64px",
                        }}
                      >
                        <h5 className={`card-title  ${styles.card_titles}`}>
                          {x.name}
                        </h5>
                        <p className={`card-text ${styles.card_texts}`}>
                          {x.description.length > userFavDescriptionLimit
                            ? `${x.description.substr(
                                0,
                                userFavDescriptionLimit
                              )}...`
                            : x.description}
                        </p>
                        <a
                          href={x.url}
                          target="_blank"
                          className={`btn ${styles.modal_btn} ms-0`}
                          style={{ marginTop: "auto" }}
                        >
                          {x.caption}
                        </a>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </section>
      )}

      {results && (
        <section>
          <div className="container-fluid">
            <div className={`${styles.result_section} mx-2  `}>
              <div className="row">
                <div className="col-xl-8 position-relative">
                  <h1 className="mb-0">Your startup growth checkup</h1>
                  <p className="mb-0">Let’s dig into your results</p>
                  <div className="mt-5">
                    <RadarChart
                      scorecard={scorecard}
                      classes={styles.chart_logo}
                    />
                  </div>
                </div>
                <div className="col-xl-4 position-relative">
                  <div className={`${styles.category_card}`}>
                    <div className={`${styles.category_list_item}`}>
                      <div className="row">
                        <div className={`col-md-12 ${styles.public_text} `}>
                          <p>
                            These are questions from the assessment where you
                            have the most room for improvement! We will suggest
                            some MATTER resources in the coming pages to help
                            you fill in these gaps.
                          </p>
                        </div>
                        <div className="col-md-3">
                          <Rating
                            name="read-only"
                            value={bullets?.BUSINESS?.percentage || 20}
                            size="small"
                            icon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{ color: `${bullets?.BUSINESS?.color}` }}
                              />
                            }
                            emptyIcon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{ color: "#E0E2E7" }}
                              />
                            }
                            readOnly
                          />
                        </div>
                        <div className="col-md-9 p-0">
                          <div className={`${styles.category_list_desc} ps-2`}>
                            <h2 className="mb-0">Business model</h2>
                            <ul className="mb-0">
                              {startupDetails &&
                                startupDetails?.low_scoring_questions_of_categories?.BUSINESS?.questions?.map(
                                  (x, x_index) => (
                                    <li key={x_index}>{x.question}</li>
                                  )
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.category_list_item}`}>
                      <div className="row">
                        <div className="col-md-3">
                          <Rating
                            name="read-only"
                            value={bullets?.RESOURCES?.percentage || 0}
                            size="small"
                            icon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{
                                  color: `${bullets?.RESOURCES?.color}`,
                                }}
                              />
                            }
                            emptyIcon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{ color: "#E0E2E7" }}
                              />
                            }
                            readOnly
                          />
                        </div>
                        <div className="col-md-9 p-0">
                          <div className={`${styles.category_list_desc} ps-2`}>
                            <h2 className="mb-0">Resources</h2>
                            <ul className="mb-0">
                              {startupDetails &&
                                startupDetails?.low_scoring_questions_of_categories?.RESOURCES?.questions?.map(
                                  (x, index) => (
                                    <li key={index}>{x.question}</li>
                                  )
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.category_list_item}`}>
                      <div className="row">
                        <div className="col-md-3">
                          <Rating
                            name="read-only"
                            value={bullets?.MARKET?.percentage || 0}
                            size="small"
                            icon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{ color: `${bullets?.MARKET?.color}` }}
                              />
                            }
                            emptyIcon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{ color: "#E0E2E7" }}
                              />
                            }
                            readOnly
                          />
                        </div>
                        <div className="col-md-9 p-0">
                          <div className={`${styles.category_list_desc} ps-2`}>
                            <h2 className="mb-0">Market</h2>
                            <ul className="mb-0">
                              {startupDetails &&
                                startupDetails?.low_scoring_questions_of_categories?.MARKET?.questions?.map(
                                  (x, index) => (
                                    <li key={index}>{x.question}</li>
                                  )
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.category_list_item}`}>
                      <div className="row">
                        <div className="col-md-3">
                          <Rating
                            name="read-only"
                            value={bullets?.TEAM?.percentage || 0}
                            size="small"
                            icon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{ color: `${bullets?.TEAM?.color}` }}
                              />
                            }
                            emptyIcon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{ color: "#E0E2E7" }}
                              />
                            }
                            readOnly
                          />
                        </div>
                        <div className="col-md-9 p-0">
                          <div className={`${styles.category_list_desc} ps-2`}>
                            <h2 className="mb-0">Team</h2>
                            <ul className="mb-0">
                              {startupDetails &&
                                startupDetails?.low_scoring_questions_of_categories?.TEAM?.questions?.map(
                                  (x, index) => (
                                    <li key={index}>{x.question}</li>
                                  )
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.category_list_item}`}>
                      <div className="row">
                        <div className="col-md-3">
                          <Rating
                            name="read-only"
                            value={bullets?.INNOVATION?.percentage || 0}
                            size="small"
                            icon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{
                                  color: `${bullets?.INNOVATION?.color}`,
                                }}
                              />
                            }
                            emptyIcon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{ color: "#E0E2E7" }}
                              />
                            }
                            readOnly
                          />
                        </div>
                        <div className="col-md-9 p-0">
                          <div className={`${styles.category_list_desc} ps-2`}>
                            <h2 className="mb-0">Innovation</h2>
                            <ul className="mb-0">
                              {startupDetails &&
                                startupDetails?.low_scoring_questions_of_categories?.INNOVATION?.questions?.map(
                                  (x, index) => (
                                    <li key={index}>{x.question}</li>
                                  )
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.category_list_item} border-bottom-0`}
                    >
                      <div className="row">
                        <div className="col-md-3">
                          <Rating
                            name="read-only"
                            value={bullets?.LEGAL?.percentage || 0}
                            size="small"
                            icon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{ color: `${bullets?.LEGAL?.color}` }}
                              />
                            }
                            emptyIcon={
                              <CircleIcon
                                fontSize="inherit"
                                style={{ color: "#E0E2E7" }}
                              />
                            }
                            readOnly
                          />
                        </div>
                        <div className="col-md-9 p-0">
                          <div className={`${styles.category_list_desc} ps-2`}>
                            <h2 className="mb-0">Legal</h2>
                            <ul className="mb-0">
                              {startupDetails &&
                                startupDetails?.low_scoring_questions_of_categories?.LEGAL?.questions?.map(
                                  (x, index) => (
                                    <li key={index}>{x.question}</li>
                                  )
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {goal && (
        <section>
          <div className={`container my-5`}>
            <div className={`row`}>
              <div className={`col-sm-12`}>
                <div className={`d-flex flex-row mb-3`}>
                  <div>
                    <img src="/assets/hexagonal.png" width="80px" alt="" />
                  </div>
                  <div className={`col-sm-7 mx-2 my-3`}>
                    <h1 className={`h1 ${styles.head_1}`}>Set your goals</h1>
                    <p className={`h5 ${styles.para_1}`}>
                      Choose which goals you want to work on over the next{" "}
                      <strong>3-6 months</strong>. We’ll use these goals, along
                      with the results from the previous page, to suggest
                      resources from the MATTER ecosystem on your Roadmap.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`container px-4`}>
            <div className={`row gx-4 row-sm`}>
              <div className={`col-xl-6`}>
                <div className={`p-4 border  ${styles.box}`}>
                  {/* <h2 className={`my-0 ${styles.head_2}`}>Select goals</h2>
                                    <p className={`my-3 ${styles.para_2}`}>
                                        Select which goals you'd like to work on over the next 3
                                        <br />
                                        -6 months.
                                    </p> */}
                  <ul className={`list-group`}>
                    <p className={`h4 ${styles.my_label}`}>Business</p>
                    {goals?.business.map((x, g_index) => (
                      <li
                        key={g_index}
                        className={`list-group-item d-flex align-items-center ${styles.li_css}`}
                      >
                        <input
                          className={`form-check-input ${styles.custom_checkbox}`}
                          type="checkbox"
                          checked={selectedTextValues.includes(x.text)}
                          id={`checkbox_${x.name}`}
                          name={`${x.name}`}
                          onChange={handleCheckboxChange}
                        />
                        <label className={`form-check-label mx-2`}>
                          {x.text}
                        </label>
                      </li>
                    ))}
                  </ul>
                  <hr />
                  <ul className={`list-group`}>
                    <p className={`h4 ${styles.my_label}`}>Product</p>
                    {goals?.product.map((x, index) => (
                      <li
                        key={index}
                        className={`list-group-item d-flex align-items-center ${styles.li_css}`}
                      >
                        <input
                          className={`form-check-input ${styles.custom_checkbox}`}
                          type="checkbox"
                          checked={selectedTextValues.includes(x.text)}
                          id={`checkbox_${x.name}`}
                          name={`${x.name}`}
                          onChange={handleCheckboxChange}
                        />
                        <label className={`form-check-label mx-2`}>
                          {x.text}
                        </label>
                      </li>
                    ))}
                  </ul>
                  <hr />
                  <ul className={`list-group`}>
                    <p className={`h4 ${styles.my_label}`}>Team</p>
                    {goals?.team.map((x, index) => (
                      <li
                        key={index}
                        className={`list-group-item d-flex align-items-center ${styles.li_css}`}
                      >
                        <input
                          className={`form-check-input ${styles.custom_checkbox}`}
                          type="checkbox"
                          checked={selectedTextValues.includes(x.text)}
                          id={`checkbox_${x.name}`}
                          name={`${x.name}`}
                          onChange={handleCheckboxChange}
                        />
                        <label className={`form-check-label mx-2`}>
                          {x.text}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className={`col-xl-6 px-4 position-relative`}>
                <div className={`p-3 border rounded-3 ${styles.extra}`}>
                  {selectedTextValues.length > 0 && (
                    <>
                      <h2 className={`w-100 my-3 ${styles.second_head}`}>
                        Are these prioritized correctly?
                      </h2>
                      <p className={`w-100 my-3 ${styles.para_4}`}>
                        Drag and drop the goals below so we can prioritize them
                        from highest priority to lowest on the next page.
                      </p>
                    </>
                  )}
                  {selectedTextValues.length > 0 ? (
                    selectedTextValues.map((x, i) => (
                      <div
                        key={i}
                        className={`card w-76 p-2 ${styles.ln_25}`}
                        draggable="true" // Make the card draggable
                        onDragStart={(e) => {
                          e.dataTransfer.setData("text/plain", i); // Set the data to be transferred (index)
                        }}
                        onDragOver={(e) => {
                          e.preventDefault();
                        }}
                        onDrop={(e) => {
                          e.preventDefault();
                          const sourceIndex =
                            e.dataTransfer.getData("text/plain"); // Get the source index
                          const targetIndex = i; // Get the target index
                          const updatedTextValues = [...selectedTextValues];

                          // Swap the positions of cards in the array
                          const temp = updatedTextValues[sourceIndex];
                          updatedTextValues[sourceIndex] =
                            updatedTextValues[targetIndex];
                          updatedTextValues[targetIndex] = temp;

                          setSelectedTextValues(updatedTextValues);
                        }}
                      >
                        <div
                          className={`card-body d-flex align-items-center  ${styles.flex_3}`}
                        >
                          <i
                            className="fas fa-bars"
                            style={{ fontSize: "22px", marginTop: "-5px" }}
                          ></i>
                          <div className={`mx-2 py-1 mt-3`}>
                            <div className={`rounded-circle ${styles.round}`}>
                              {i + 1 < 10 ? `0${i + 1}` : i + 1}
                            </div>
                          </div>

                          <div className={`h5 ${styles.seperate}`}>
                            <h5 className={`card-title  ${styles.head}`}>
                              {x}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={`card w-76 ${styles.card}`}>
                      <div className={`card-body p-0 position-relative`}>
                        <img
                          src="/assets/arrow.svg"
                          alt=""
                          className={`${styles.arrow_img}`}
                        />
                        <div className={`h5`}>
                          <h5 className={`card-title ${styles.card_title}`}>
                            To get started, select goals
                          </h5>
                          <p className={`${styles.card_text}`}>
                            In a moment, you’ll see videos, articles and mentors
                            that have been customized to your goals for the next{" "}
                            <strong>3-6 months</strong>.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {recommendations && (
        <section className="px-5">
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="text-center">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <img src="/assets/check.svg" alt="" />
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={`${styles.modal_title}`}
                  sx={{ mt: 2 }}
                >
                  Let’s see your personalized recommendations
                </Typography>
                <p className={`${styles.modal_description}`}>
                  In a moment, you’ll see videos, articles and mentorship
                  meetings that have been customized to your goals for the next
                  3-6 months.
                </p>
                <button
                  type="button"
                  className={`btn ${styles.modal_btn}`}
                  onClick={() => handleClose()}
                >
                  Let's go
                </button>
              </Box>
            </Modal>
          </div>
          <div className="row px-5">
            <div className="col-md-12 mt-5">
              <h4 className={`${styles.recommened_title} text-black`}>
                Your personalized MATTER roadmap
              </h4>
            </div>
            <div className="col-md-4">
              <div className={`${styles.dropdown} dropdown`}>
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className={`${styles.first_text}`}>Showing:</span>{" "}
                  <span className={`${styles.second_text}`}>
                    {showingFilter}
                  </span>
                </button>
                <ul
                  className={`${styles.dropdown_menu} dropdown-menu`}
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        setShowingFilter("Bookmarked");
                      }}
                    >
                      Bookmarked
                    </a>
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        setShowingFilter("Incomplete");
                      }}
                    >
                      Incomplete
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        setShowingFilter("Completed");
                      }}
                    >
                      Complete
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles.dropdown} dropdown`}>
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className={`${styles.first_text}`}>Filter by:</span>{" "}
                  <span className={`${styles.second_text}`}>
                    {currentCategory}
                  </span>
                </button>
                <ul
                  className={`${styles.dropdown_menu} dropdown-menu`}
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        setCategory("");
                        setCurrentCategory("All resource programs");
                      }}
                    >
                      All resource programs
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        setCategory("expert clinic");
                        setCurrentCategory("Expert clinic");
                      }}
                    >
                      Expert clinic
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        setCategory("digital curriculum");
                        setCurrentCategory("Digital curriculum");
                      }}
                    >
                      Digital curriculum
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        setCategory("knowledge library");
                        setCurrentCategory("Knowledge library");
                      }}
                    >
                      Knowledge library
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        setCategory("professional services directory");
                        setCurrentCategory("Professional services directory");
                      }}
                    >
                      Professional services directory
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles.input_filter}`}>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    <SearchIcon />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => {
                      searchFilter(e);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* accordions */}
            <CustomizedAccordions
              data={resourcesByGoal}
              parentRequest={handlePatchRequestsFromChildComponent}
            />
            {showContent && (
              <>
                <div className="col-md-12 mb-5" key={goal}>
                  <div className={`${styles.section_title} text-center`}>
                    <h5> No data available</h5>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default NewDesign;
